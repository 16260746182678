import { DownloadInformation } from 'types/survey';
import { APICore } from './apiCore';

const alphaSurveyApi = 'api/alpha/survey';

const api = new APICore();

export const verifyToken = async (token: string) => {
  return api.get(`${process.env.REG_SVC_ADDRESS}/${alphaSurveyApi}/token/${token}`);
};

export const saveDownloadInfo = async (downloadInfo: DownloadInformation) => {
  return api.create(`${process.env.REG_SVC_ADDRESS}/${alphaSurveyApi}/download`, downloadInfo);
};
