import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Survey as SurveyResp, SurveyAnswersDTO } from 'types/survey';

interface Survey {
  isOpen: boolean;
  data: any;
  token?: string;
  page?: string;
}

const initialState = { data: {}, isOpen: false } as Survey;

const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    showSurvey(state) {
      state.isOpen = true;
    },
    closeSurvey(state, action: PayloadAction<{ resetData: boolean }>) {
      state.isOpen = false;
      if (action.payload.resetData) {
        state.data = {};
        state.page = undefined;
      }
    },
    changed(state: Survey, action: PayloadAction<{ name: string; value: string | string[] }>) {
      state.data[action.payload.name] = action.payload.value;
    },
    submitted(state: Survey, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    pageChanged(state: Survey, action: PayloadAction<{ page: string }>) {
      state.page = action.payload.page;
    },
  },
});

export const { changed, closeSurvey, showSurvey, submitted, pageChanged } = surveySlice.actions;
export default surveySlice.reducer;

const axiosBaseQuery =
  (
    baseUrl: string
  ): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      withCredentials?: AxiosRequestConfig['withCredentials'];
      extraConfig?: AxiosRequestConfig;
    },
    AxiosResponse,
    { status: number; message: string }
  > =>
  async ({ url, method = 'GET', data, params, withCredentials = false, extraConfig }) => {
    try {
      const adjustedUrl = `${baseUrl}${url}`;
      return await axios({ url: adjustedUrl, method, data, params, withCredentials, ...extraConfig });
    } catch (err) {
      return { error: err };
    }
  };

export const api = createApi({
  reducerPath: 'survey-query',
  baseQuery: axiosBaseQuery(`${process.env.REG_SVC_ADDRESS}/api/alpha/survey`),
  keepUnusedDataFor: 3600,
  tagTypes: ['Survey'],
  endpoints: (builder) => ({
    getSurvey: builder.query<SurveyResp, string>({
      query: (language) => ({ url: '', params: { language } }),
      providesTags: ['Survey'],
    }),
    sendSurveyAnswers: builder.mutation<string, SurveyAnswersDTO>({
      query: (surveyAnswers) => ({
        url: '',
        data: surveyAnswers,
        method: 'POST',
      }),
    }),
  }),
});

export const { useGetSurveyQuery, useSendSurveyAnswersMutation } = api;
