import { graphql, useStaticQuery } from 'gatsby';

export const isBrowser = typeof window !== 'undefined';

export const determineImagePath = (imageName?: string) => {
  const imageSharps = useStaticQuery<Queries.ImageSharpQuery>(graphql`
    query ImageSharp {
      allImageSharp {
        nodes {
          gatsbyImageData
        }
      }
    }
  `);

  if (!imageName?.length) return undefined;
  const imageSharp = imageSharps.allImageSharp.nodes.find((node) =>
    node.gatsbyImageData.images.fallback.src.endsWith(imageName)
  );
  if (!imageSharp) return undefined;
  return imageSharp.gatsbyImageData.images.fallback.src;
};

type MDPageContentNodes = {
  frontmatter: {
    belongs_to: string;
  };
}[];
export function groupContentByPage<T extends MDPageContentNodes>(nodes: T) {
  return nodes.reduce(
    (acc, node) => {
      if (!acc[node.frontmatter.belongs_to]) {
        acc[node.frontmatter.belongs_to] = [] as T;
      }
      acc[node.frontmatter.belongs_to].push(node);
      return acc;
    },
    {} as Record<string, T>
  );
}
