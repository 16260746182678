import { createSlice } from '@reduxjs/toolkit';

interface Layout {
  isLoginOpen: boolean;
  isRegisterOpen: boolean;
  isForgetPassOpen: boolean;
}

const initialState: Layout = { isLoginOpen: false, isRegisterOpen: false, isForgetPassOpen: false };

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    toggleLogin(state) {
      state.isLoginOpen = !state.isLoginOpen;
      state.isRegisterOpen = false;
      state.isForgetPassOpen = false;
    },
    toggleRegister(state) {
      state.isRegisterOpen = !state.isRegisterOpen;
      state.isLoginOpen = false;
      state.isForgetPassOpen = false;
    },
    showRegisterSuccess(state) {
      state.isRegisterOpen = false;
      state.isLoginOpen = false;
      state.isForgetPassOpen = false;
    },
    toggleForgetPass(state) {
      state.isForgetPassOpen = !state.isForgetPassOpen;
      state.isLoginOpen = false;
      state.isRegisterOpen = false;
    },
    closeAccountLayout(state) {
      state.isLoginOpen = false;
      state.isRegisterOpen = false;
      state.isForgetPassOpen = false;
    },
  },
});

export const { toggleLogin, toggleRegister, toggleForgetPass, closeAccountLayout } = layoutSlice.actions;
export default layoutSlice.reducer;
