exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-password-reset-tsx": () => import("./../../../src/pages/password-reset.tsx" /* webpackChunkName: "component---src-pages-password-reset-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-pages-verify-register-tsx": () => import("./../../../src/pages/verify-register.tsx" /* webpackChunkName: "component---src-pages-verify-register-tsx" */),
  "component---src-templates-index-template-tsx": () => import("./../../../src/templates/IndexTemplate.tsx" /* webpackChunkName: "component---src-templates-index-template-tsx" */),
  "component---src-templates-md-page-template-tsx": () => import("./../../../src/templates/MDPageTemplate.tsx" /* webpackChunkName: "component---src-templates-md-page-template-tsx" */),
  "component---src-templates-tag-template-tsx": () => import("./../../../src/templates/TagTemplate.tsx" /* webpackChunkName: "component---src-templates-tag-template-tsx" */)
}

