import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

type User = {
  email: string;
  password: string;
};

const users: User[] = [
  {
    email: 'test',
    password: 'test',
  },
  {
    email: 'dev@raincld.eu',
    password: 'mypass1234',
  },
];

export const configureFakeBackend = () => {
  console.log('Using fake backend for Axios request!');
  const mock = new MockAdapter(axios, { delayResponse: 1000 });

  mock.onPost(`${process.env.SAT_ADDRESS}/api/v0/auth/token`).reply((config) => {
    const params = JSON.parse(config.data);
    const filteredUser = users.find((user) => user.email === params.email && user.password === params.password);
    return filteredUser ? [200, filteredUser] : [401, { message: 'Email or password is incorrect' }];
  });

  const passwordForgotUrl = new RegExp(`${process.env.REG_SVC_ADDRESS}\/api\/alpha\/account\/password\/forgot\/*`);
  mock.onGet(passwordForgotUrl).reply(200);

  mock.onPost(`${process.env.REG_SVC_ADDRESS}/api/alpha/survey/download`).reply(200, {
    id: 1,
    alphaToken: '74306d01-68ab-463c-8feb-3712f62f841a',
    created_at: '2021-09-29T22:05:07.270396459+02:00',
    version: 'v1.0.0',
    platform: 'unix',
  });

  mock.onPost(`${process.env.REG_SVC_ADDRESS}/api/alpha/account/register`).reply(201);

  const updatePasswordUrl = new RegExp(`${process.env.REG_SVC_ADDRESS}\/api\/alpha\/account\/reset`);
  mock.onPut(updatePasswordUrl).reply((config) => {
    const matches = config.url?.match(/\?token=.+$/);
    if (!matches?.length) return [400, 'No token provided'];
    if (!config.data?.newPassword?.length) return [400, 'Missing password'];
    return [200, 'password updated successfully'];
  });

  const verifyTokenUrl = new RegExp(`${process.env.REG_SVC_ADDRESS}\/api\/alpha\/survey\/token\/*`);
  mock.onGet(verifyTokenUrl).reply(200);

  const activateEmailUrl = new RegExp(`${process.env.REG_SVC_ADDRESS}\/api\/alpha\/account\/activate`);
  mock.onPost(activateEmailUrl).reply((config) => {
    const matches = config.url?.match(/\?token=.+$/);
    if (!matches?.length) return [400, 'No token provided'];
    return [201, users.at(0)];
  });
  mock.onPost(activateEmailUrl).replyOnce(400); //Test failure

  mock.onGet(`${process.env.REG_SVC_ADDRESS}/api/alpha/survey`).reply(() => [200, mockSurveyData]);

  mock
    .onPost(`${process.env.REG_SVC_ADDRESS}/api/alpha/survey`)
    .replyOnce(500)
    .onPost(`${process.env.REG_SVC_ADDRESS}/api/alpha/survey`)
    .reply(() => [201, '8d0f69de-3894-42d4-a2fa-537a7112c4af']);
};

const mockSurveyData = {
  id: 1,
  showProgressBar: 'top',
  progressBarType: 'buttons',
  pages: [
    {
      id: 2,
      name: 'Intro',
      navigationTitle: 'Intro',
      navigationDescription: 'Intro',
      elements: [
        {
          id: 3,
          name: 'Intro',
          type: 'html',
          description: '',
          title: '',
          choices: [],
          colCount: 0,
          createdAt: '2023-07-03T14:15:45.562484Z',
          isRequired: false,
          tooltip: '',
          validators: [],
          inputtype: '',
          autoComplete: '',
          html: '<h2>... First a Quick Survey - Why?</h2>            <p>It is of crucial importance for your <i>User Experience</i> while using <span className="raincloud">Rain،cloud</span>,            <ul>              <li>that we know what the technical limitations of our member\'s infrastructure are</li>              <li>that you actually fulfil the requirements to make everything work smoothly</li>            </ul>            </p>            <p>This enables us to make <span className="raincloud">Rain،cloud</span> work for our members on the largest possible range            of different devices and infrastructure.            </p>            <p>Also, this survey helps you and us determine whether you fulfil the minimum technical requirements to participate            in the alpha test, so we can make sure everybody has realistic expectations and don\'t risk            wasting time in case they cannot currently fulfil these requirements.            </p>            <p>Even if it turns out that you cannot (yet) fulfil the minimum participation requirements, we would be very grateful             if you finish and submit the survey, so we can get an idea whether we should try hard to simplify these requirements.<br />            </p>            <p>Regardless of the outcome, we hope to welcome you now or in the future as a valued member!</p>            <p>Heartfelt Thanks!</p>            <p><i>Your <span className="raincloud">Rain،cloud</span> Team</i></p>            ',
          visibleIf: '',
          startWithNewLine: true,
          language: 'en',
        },
      ],
      language: 'en',
    },
    {
      id: 4,
      name: 'Age',
      navigationTitle: 'Age',
      navigationDescription: 'Minimum age',
      elements: [
        {
          id: 5,
          name: 'age14',
          type: 'radiogroup',
          description: "You have to be older than 14 years to use <span class='rc-text'>Rain،cloud</span>.",
          title: 'I am older than 14 years',
          choices: ['Yes', 'No'],
          colCount: 4,
          createdAt: '2023-07-03T14:15:46.357452Z',
          isRequired: true,
          tooltip: "You have to be older than 14 years to use <span class='rc-text'>Rain،cloud</span>.",
          validators: [
            {
              type: 'expression',
              expression: '{age14} == "yes"',
              text: 'You have to be older than 14 years to continue. Ask you legal guardian to...',
              language: 'en',
            },
          ],
          inputtype: '',
          autoComplete: '',
          html: '',
          visibleIf: '',
          startWithNewLine: true,
          language: 'en',
        },
      ],
      language: 'en',
    },
    {
      id: 6,
      name: 'Info',
      navigationTitle: 'Information',
      navigationDescription: 'General Information',
      elements: [
        {
          id: 7,
          name: 'whereHow',
          type: 'checkbox',
          description: '',
          title: 'Where/how did you hear about Rain،cloud?',
          choices: [
            'From the Rain،cloud website',
            'From an online article',
            'From a specific search for e.g. cloud storage or cloud backup',
            'From another Rain،cloud user',
            'From an acquaintance/friend',
            'I was personally invited by the Rain،cloud Team',
          ],
          colCount: 2,
          createdAt: '2023-07-03T14:15:48.084501Z',
          isRequired: true,
          tooltip: '',
          validators: [],
          inputtype: '',
          autoComplete: '',
          html: '',
          visibleIf: '',
          startWithNewLine: true,
          language: 'en',
        },
        {
          id: 8,
          name: 'fairPrice',
          type: 'text',
          description:
            'What would you consider a fair monthly subscription fee for <i>50% unlimited backup space</i>? (Please give a number for € or $)',
          title: 'Fair Price',
          choices: [],
          colCount: 0,
          createdAt: '2023-07-03T14:15:50.459685Z',
          isRequired: true,
          tooltip:
            'By <i>50% unlimited backup space</i> we mean that you would use 50% of the total storage space on your NAS to store your data,             and you would contribute the other 50% to <span className=\'raincloud\'>Rain،cloud</span>.            <b><i>"Unlimited"</i></b> refers to the fact that your backup space is only limited by the storage capacity you choose to install in your NAS             (which is of course ultimately also subject to technical limitations).',
          validators: [],
          inputtype: '',
          autoComplete: '',
          html: '',
          visibleIf: '{whereHow} != ""',
          startWithNewLine: true,
          language: 'en',
        },
      ],
      language: 'en',
    },
    {
      id: 9,
      name: 'Contact',
      navigationTitle: 'Contact',
      navigationDescription: 'Contact information',
      elements: [
        {
          id: 10,
          name: 'info',
          type: 'html',
          description: '',
          title: '',
          choices: [],
          colCount: 0,
          createdAt: '2023-07-03T14:16:15.685866Z',
          isRequired: false,
          tooltip: '',
          validators: [],
          inputtype: '',
          autoComplete: '',
          html: "<div><h5>Please submit your answers to see if you meet our requirements to become a member of the <span class='rc-text'>Rain،cloud</span> family.</h5><h6>If you don't meet some requirements, you can still participate in the alpha phase or later become a member after fulfilling them.</h6></div>",
          visibleIf: '',
          startWithNewLine: true,
          language: 'en',
        },
        {
          id: 11,
          name: 'reqAgeNot',
          type: 'html',
          description: '',
          title: '',
          choices: [],
          colCount: 0,
          createdAt: '2023-07-03T14:16:16.009275Z',
          isRequired: false,
          tooltip: '',
          validators: [],
          inputtype: '',
          autoComplete: '',
          html: '<h5>You have to be older than 14</h5><p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class=text-danger viewBox="0 0 16 16"><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/></svg> You are younger!</p>',
          visibleIf: '{age14} = "no"',
          startWithNewLine: true,
          language: 'en',
        },
        {
          id: 12,
          name: 'reqAge',
          type: 'html',
          description: '',
          title: '',
          choices: [],
          colCount: 0,
          createdAt: '2023-07-03T14:16:17.713327Z',
          isRequired: false,
          tooltip: '',
          validators: [],
          inputtype: '',
          autoComplete: '',
          html: '<h5>You have to be older than 14</h5><p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class=text-success viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" /></svg> You are older!</p>',
          visibleIf: '{age14} = "yes"',
          startWithNewLine: true,
          language: 'en',
        },
        {
          id: 13,
          name: 'promiseFulfillment',
          type: 'radiogroup',
          description: '',
          title: 'I will fulfill the requirements for the Alpha and want to participate.',
          choices: ['Yes, I will!', "No, I won't!"],
          colCount: 2,
          createdAt: '2023-07-03T14:16:19.265237Z',
          isRequired: true,
          tooltip: '',
          validators: [],
          inputtype: '',
          autoComplete: '',
          html: '',
          visibleIf: '{age14} = "no"',
          startWithNewLine: true,
          language: 'en',
        },
        {
          id: 14,
          name: 'email',
          type: 'text',
          description: '',
          title: 'My email address is:',
          choices: [],
          colCount: 0,
          createdAt: '2023-07-03T14:16:20.315464Z',
          isRequired: true,
          tooltip: '',
          validators: [
            {
              type: 'email',
              expression: '',
              text: '',
              language: 'en',
            },
          ],
          inputtype: 'email',
          autoComplete: 'email',
          html: '',
          visibleIf: '',
          startWithNewLine: true,
          language: 'en',
        },
        {
          id: 15,
          name: 'agree',
          type: 'checkbox',
          description: '',
          title: 'I agree with the usage of my data.',
          choices: ['Yes, i agree.'],
          colCount: 2,
          createdAt: '2023-07-03T14:16:20.975335Z',
          isRequired: true,
          tooltip: '',
          validators: [],
          inputtype: '',
          autoComplete: '',
          html: '',
          visibleIf: '',
          startWithNewLine: true,
          language: 'en',
        },
      ],
      language: 'en',
    },
  ],
  showQuestionNumbers: 'on',
  language: 'en',
  version: '0.0.1',
  updatedAt: '2023-07-03T14:15:11.87395Z',
  active: true,
};
