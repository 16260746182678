import { UserData } from 'types/survey';
import { APICore } from './apiCore';

const api = new APICore();
const alphaAccountApi = 'api/alpha/account';

export const signUp = async (userData: UserData, language: string) => {
  return api.create(`${process.env.REG_SVC_ADDRESS}/${alphaAccountApi}/register`, { ...userData, language });
};

export const signIn = (params: { email: string; password: string }) => {
  return api.create(`${process.env.SAT_ADDRESS}/api/v0/auth/token`, params);
  // return api.create(`${process.env.REG_SVC_ADDRESS}/api/alpha/account/info`, params); //TODO: Validate credentials with regserv or satellite?
};

export const activateEmail = (tokenParam: string, language: string) => {
  return api.create(`${process.env.REG_SVC_ADDRESS}/${alphaAccountApi}/activate?${tokenParam}`, { language });
};

export const forgotPassword = (email: string, language: string) => {
  return api.create(`${process.env.REG_SVC_ADDRESS}/${alphaAccountApi}/reset/${email}`, { language });
};

export const updatePassword = async (password: string, token: string) => {
  return api.update(`${process.env.REG_SVC_ADDRESS}/${alphaAccountApi}/reset/${token}`, { newPassword: password });
};
