import { configureStore } from '@reduxjs/toolkit';
import surveyReducer, { api as surveyApi } from './survey';
import authReducer from './auth';
import layoutReducer from './layout';
import { isBrowser } from '@/helpers';

const STATE_KEY = 'survey';

const saveToLocalStorage = (state: any) => {
  if (!isBrowser) return;
  try {
    const serialisedState = JSON.stringify(state);
    localStorage.setItem(STATE_KEY, serialisedState);
  } catch (e) {
    console.warn(e);
  }
};

const loadFromLocalStorage = () => {
  if (!isBrowser) return;
  try {
    const serialisedState = localStorage.getItem(STATE_KEY);
    if (serialisedState === null) return undefined;
    return { Survey: JSON.parse(serialisedState) };
  } catch (e) {
    console.warn(e);
    return undefined;
  }
};

const store = configureStore({
  reducer: {
    Survey: surveyReducer,
    Auth: authReducer,
    Layout: layoutReducer,
    [surveyApi.reducerPath]: surveyApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(surveyApi.middleware),
  preloadedState: loadFromLocalStorage(),
});

store.subscribe(() => saveToLocalStorage(store.getState().Survey));

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default () => store;
