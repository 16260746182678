import axios, { AxiosRequestConfig } from 'axios';
import { isBrowser } from 'helpers/core';
import { RejectedResponse } from 'types/utils';

// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';

// intercepting to capture errors
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let rejectedResp: RejectedResponse;
    if (axios.isAxiosError(error)) {
      rejectedResp = {
        message: getMessageForStatus(
          error.response?.status,
          error.response?.data ? JSON.stringify(error.response.data) : error.message
        ),
        status: error.response?.status,
        data: error.response?.data,
      };
    } else {
      rejectedResp = {
        message: error ? JSON.stringify(error) : 'Unknown error',
        status: error?.status || error?.Status,
        data: undefined,
      };
    }
    return Promise.reject(rejectedResp);
  }
);

const getMessageForStatus = (status: number | undefined, defaultMessage: string) => {
  switch (status) {
    case 403:
      return 'Access Forbidden';
    case 404:
      return 'Sorry! the data you are looking for could not be found';
    default: {
      return defaultMessage;
    }
  }
};

const AUTH_SESSION_KEY = 'raincloud_user';

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
  if (token) axios.defaults.headers.common['Authorization'] = 'JWT ' + token;
  else delete axios.defaults.headers.common['Authorization'];
};

const getUserFromSession = () => {
  const user = isBrowser ? sessionStorage.getItem(AUTH_SESSION_KEY) : null;
  return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};
class APICore {
  /**
   * Fetches data from given url
   */
  get = (url: string, params?: any) => {
    let response;
    if (params) {
      const queryString = params
        ? Object.keys(params)
            .map((key) => key + '=' + params[key])
            .join('&')
        : '';
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }
    return response;
  };

  getFile = (url: string, params: any) => {
    let response;
    if (params) {
      const queryString = params
        ? Object.keys(params)
            .map((key) => key + '=' + params[key])
            .join('&')
        : '';
      response = axios.get(`${url}?${queryString}`, { responseType: 'blob' });
    } else {
      response = axios.get(`${url}`, { responseType: 'blob' });
    }
    return response;
  };

  getMultiple = (urls: string, params: any) => {
    const reqs = [];
    let queryString = '';
    if (params) {
      queryString = params
        ? Object.keys(params)
            .map((key) => key + '=' + params[key])
            .join('&')
        : '';
    }

    for (const url of urls) {
      reqs.push(axios.get(`${url}?${queryString}`));
    }
    return axios.all(reqs);
  };

  /**
   * post given data to url
   */
  create = (url: string, data: any, config?: AxiosRequestConfig) => {
    return axios.post(url, data, config);
  };

  /**
   * Updates patch data
   */
  updatePatch = (url: string, data: any) => {
    return axios.patch(url, data);
  };

  /**
   * Updates data
   */
  update = (url: string, data: any) => {
    return axios.put(url, data);
  };

  /**
   * Deletes data
   */
  delete = (url: string) => {
    return axios.delete(url);
  };

  /**
   * post given data to url with file
   */
  createWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config: any = {
      headers: {
        ...axios.defaults.headers,
        'content-type': 'multipart/form-data',
      },
    };
    return axios.post(url, formData, config);
  };

  /**
   * post given data to url with file
   */
  updateWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config: any = {
      headers: {
        ...axios.defaults.headers,
        'content-type': 'multipart/form-data',
      },
    };
    return axios.patch(url, formData, config);
  };

  isUserAuthenticated = () => {
    const user = this.getLoggedInUser();
    return !!user;
    // if (!user || (user && !user.token)) {
    //     return false;
    // }
    // const decoded: any = jwtDecode(user.token);
    // const currentTime = Date.now() / 1000;
    // if (decoded.exp < currentTime) {
    //     console.warn('access token expired');
    //     return false;
    // } else {
    // return true;
    // }
  };

  setLoggedInUser = (session: any) => {
    if (!isBrowser) return;
    if (session) sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
    else {
      sessionStorage.removeItem(AUTH_SESSION_KEY);
    }
  };
  /**
   * Returns the logged in user
   */
  getLoggedInUser = () => {
    return getUserFromSession();
  };

  setUserInSession = (modifiedUser: any) => {
    const userInfo = isBrowser ? sessionStorage.getItem(AUTH_SESSION_KEY) : null;
    if (userInfo) {
      const { token, user } = JSON.parse(userInfo);
      this.setLoggedInUser({ token, ...user, ...modifiedUser });
    }
  };
}

/*
Check if token available in session
*/
const user = getUserFromSession();
if (user) {
  const { token } = user;
  if (token) {
    setAuthorization(token);
  }
}

export { APICore, setAuthorization };
