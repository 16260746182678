module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.13.1_gatsby@5.13.6_babel-eslint@10.1.0_eslint@8.57.0__eslint-plug_pomog5noqcxdgodh2jm5x53f5q/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-nprogress@5.13.1_gatsby@5.13.6_babel-eslint@10.1.0_eslint@8.57.0__eslint-plugin_zdcm7bdxxcireq5zbungbvca4a/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#ff5700","showSpinner":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.13.1_gatsby-plugin-sharp@5.13.1_gatsby@5.13.6_babel-eslint@10.1.0_esli_7m2ovgwlmvfgd4eqr3hoh6zfz4/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-lunr@1.5.2/node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en"}],"fields":[{"name":"title","store":true,"attributes":{"boost":20}},{"name":"path","store":true}],"resolvers":{"Mdx":{}}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.6_babel-eslint@10.1.0_eslint@8.57.0__eslint-plugin-_metnoigkhjse376xgpjnik34ra/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Rain،cloud","short_name":"Rain،cloud","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"minimal-ui","icon":"static/favicon/favicon-32.png","icons":[{"src":"/favicon/favicon-192.png","sizes":"192x192","type":"image/png"},{"src":"/favicon/favicon-48.png","sizes":"48x48","type":"image/png"},{"src":"/favicon/favicon-32.png","sizes":"32x32","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9287b2dc2efc8538025798874b7e1a8d"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-react-redux@1.1.0_react-redux@9.1.2_@types+react@18.3.3_react@18.3.1_redux@4.2.1__react@18.3.1/node_modules/gatsby-plugin-react-redux/gatsby-browser.js'),
      options: {"plugins":[],"pathToCreateStoreModule":"./src/redux/store.ts"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-react-i18next@3.0.1_gatsby@5.13.6_babel-eslint@10.1.0_eslint@8.57.0__eslint-plu_vh7zyfwkamcriqvtsg7i6q7g6m/node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","de","nl"],"defaultLanguage":"en","siteUrl":"https://rain.cloud","i18nextOptions":{"fallbackLng":"en","supportedLngs":["en","de","nl"],"defaultNS":"common","interpolation":{"escapeValue":false}},"pages":[{"matchPath":"/:lang?/(about|contact|imprint|privacy|download|faq)","getLanguageFromPath":true}]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.6_babel-eslint@10.1.0_eslint@8.57.0__eslint-plugin-jest@24.7.0_@typescript-eslint_tk2nbk3qeogr2cikewyggm2k7a/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
