import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { signIn as signInUser, signUp as signUpUser, forgotPassword as userForgotPassword } from '@/helpers';
import { APICore } from 'helpers/api/apiCore';
import { closeAccountLayout } from './layout';
import { UserData } from 'types/survey';

type UserDataLang = UserData & {
  language: string;
};

export const signUp = createAsyncThunk(
  'auth/signup',
  async ({ email, password, fullname, nickname, language }: UserDataLang, { rejectWithValue }) => {
    try {
      const response = await signUpUser({ email, password, fullname, nickname }, language);
      if (response.status === 201) {
        return email;
      }
      return rejectWithValue(response.data);
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const signIn = createAsyncThunk(
  'auth/signin',
  async ({ email, password }: { email: string; password: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await signInUser({ email, password });
      if (response?.status === 200) {
        dispatch(closeAccountLayout());
        return email;
      }
      return rejectWithValue(response.data);
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  'auth/forget-password',
  async ({ email, language }: { email: string; language: string }, { rejectWithValue }) => {
    try {
      const response = await userForgotPassword(email, language);
      if (response?.status === 202) {
        return email;
      }
      return rejectWithValue(response.data);
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

type State = {
  email?: string;
  loading?: boolean;
  error?: string;
  resetPasswordSuccess: boolean;
  userSignUp: boolean;
};

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    error: undefined,
    resetPasswordSuccess: false,
    userSignUp: false,
  },
  reducers: {
    logout(state: State) {
      state.email = undefined;
      state.error = undefined;
      state.userSignUp = false;
      new APICore().setLoggedInUser(null);
    },
    resetError(state) {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    }),
      builder.addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.email = action.payload;
        new APICore().setLoggedInUser(action.payload);
      }),
      builder.addCase(signIn.rejected, (state, action) => {
        state.error = `${action.payload}`;
        state.loading = false;
      }),
      builder.addCase(signUp.pending, (state) => {
        state.userSignUp = false;
        state.loading = true;
        state.error = undefined;
      }),
      builder.addCase(signUp.fulfilled, (state) => {
        state.userSignUp = true;
        state.loading = false;
        state.resetPasswordSuccess = true;
      }),
      builder.addCase(signUp.rejected, (state, action) => {
        state.userSignUp = false;
        state.error = `${action.payload}`;
        state.loading = false;
      }),
      builder.addCase(forgotPassword.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      }),
      builder.addCase(forgotPassword.fulfilled, (state) => {
        state.loading = false;
        state.resetPasswordSuccess = true;
      }),
      builder.addCase(forgotPassword.rejected, (state, action) => {
        state.error = `${action.payload}`;
        state.loading = false;
        state.resetPasswordSuccess = false;
      });
  },
});

export const { logout, resetError } = authSlice.actions;
export default authSlice.reducer;
