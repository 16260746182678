import 'normalize.css';
import 'typeface-domine';
import 'typeface-montserrat';
import 'prismjs/themes/prism-okaidia.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './src/assets/icons/_dripicons.scss';
import './src/assets/icons/_materialdesignicons.scss';
import './src/assets/site.scss';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/brands.min.css';
import '@fortawesome/fontawesome-free/css/regular.min.css';
import '@fortawesome/fontawesome-free/css/solid.min.css';
import { configureFakeBackend } from './src/helpers/fake-backend';
process.env.NODE_ENV === 'development' && configureFakeBackend();

const addFadeAnimations = (condition: (elemTop: number, viewBottom: number) => boolean, cb: (elem: HTMLElement) => void) => {
    const viewTop = window.scrollY;
    const viewBottom = viewTop + window.innerHeight;
    for (const elem of document.getElementsByClassName('fade-in-transition')) {
        const htmlElem = elem as HTMLElement;
        const elemTop = htmlElem.offsetTop;
        if (condition(elemTop, viewBottom)) {
            cb(htmlElem);
        }
    }
}

const addFadeInAnimations = () => {
    addFadeAnimations((elemTop, viewBottom) => elemTop + 80 < viewBottom, (htmlElem) => htmlElem.classList.add('animate'));
}

// const addFadeOutAnimations = () => {
//     addFadeAnimations((elemTop, viewBottom) => elemTop > viewBottom - 150, (htmlElem) => htmlElem.classList.remove('animate'));
// }

export const onRouteUpdate = () => {
    const addAnimations = () => {
        addFadeInAnimations();
        // addFadeOutAnimations();
        window.removeEventListener('scroll', addFadeInAnimations);
        window.addEventListener('scroll', addFadeInAnimations);
        // window.removeEventListener('scroll', addFadeOutAnimations);
        // window.addEventListener('scroll', addFadeOutAnimations);
    };

    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    if (mediaQuery.matches) {
        return;
    } else {
        addAnimations();
    }

    mediaQuery.addEventListener('change', () => {
        if (!mediaQuery.matches) {
            addAnimations();
        }
    });
}
